import React, {useState, useEffect, useRef, Fragment} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ApiLoan from "../../../service/ApiLoanService";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toast} from "primereact/toast";
import {InputNumber} from "primereact/inputnumber";
import { WEBHOOK } from '../../../shared/questionnaireIntegrationTypes';


const QuestionnaireProviderWebhookForm = (props) => {
    const [method, setMethod] = useState(null);
    const [urlPage, setUrlPage] = useState('');
    const [body, setBody] = useState({});
    const [responseKey, setResponseKey] = useState('');
    const [responseTimeout, setResponseTimeout] = useState(5);
    const [attributes, setAttributes] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [authorization, setAuthorization] = useState({
        authorization_type: null,
        value: ''
    });
    const [validAuthorization, setValidAuthorization] = useState(false);
    const refToast = useRef(null);
    const [condition, setCondition] = useState(false);

    const recursiveExtractAllQuestionConfigValues = (obj, accumulator = []) => {
        if (obj && typeof obj === 'object') {
            if (Array.isArray(obj)) {
                obj.forEach(item => recursiveExtractAllQuestionConfigValues(item, accumulator));
            } else {
                for (let key in obj) {
                    if (key === 'question_variable_config') {
                        accumulator.push(obj[key]);
                    } else {
                        recursiveExtractAllQuestionConfigValues(obj[key], accumulator);
                    }
                }
            }
        }
    };

    useEffect(() => {
        let apiService = new ApiLoan();
        const variable = [];
        apiService.getResources({
            url: '/provider_webhook/variables'
        }).then(response => {
            recursiveExtractAllQuestionConfigValues(props.formValues, variable)
            variable.map((element) =>{
                if (element.name) {
                    response.data.objects.push({name: element.variable, type: 'string'})
                }
            })
            setAttributes(response.data.objects);
        }).catch(error => {
            props.history.push('/')
        });

        return () => {
            setAttributes([]);
        };
    }, []);

    useEffect(() => {
        props.setShowAttributes(showAttributes());
    }, [attributes]);

    useEffect(() => {
        let conditions = props?.showConditionForm || [];
        if (conditions.find((item) => item.questionnaire_integration_type_id === WEBHOOK)){
            setCondition(true);
        }
    }, [props.showConditionForm]);

    useEffect(() => {
        if(props.formData){
            setBody(props.formData.body);
            setMethod(props.formData.method);
            setUrlPage(props.formData.url);
            setAuthorization(props.formData.authorization)
            setResponseKey(props.formData.response_key);
            setResponseTimeout(props.formData.response_timeout)
        }
    }, [props.formData]);

    useEffect(() => {
        if (method !== null && urlPage !== '' && body !== null && responseKey !== '' && responseTimeout !== 0 && validAuthorization){
            setSubmitDisabled(false);
        } else {
            if (submitDisabled === false){
                setSubmitDisabled(true)
            }
        }
    }, [method, urlPage, body, responseKey, responseTimeout, validAuthorization]);

    useEffect(() => {
        let _valid = false
        if (authorization.authorization_type === 'Basic'){
            if (authorization.value.user !== ''){
                _valid = true;
            }
        } else if (authorization.authorization_type === 'Bearer'){
            if (authorization.value !== ''){
                _valid = true;
            }
        } else {
            _valid = authorization.authorization_type === null;
        }

        setValidAuthorization(_valid)
    }, [authorization.authorization_type, authorization.value]);

    const methodOptions = [
        { value: 'POST', label: 'POST' },
        { value: 'PATCH', label: 'PATCH' }
    ];

    const authorizationOptions = [
        { value: 'Basic', label: 'Basic' },
        { value: 'Bearer', label: 'Bearer' }
    ];

    const editJson = (e) => {
        if (e.new_value.startsWith('{')){
            const attribute_exist = attributes.filter(x => x.name === e.new_value)[0];
            if (attribute_exist === undefined){
                return false;
            }
        }
        setBody(e.updated_src);
    }

    const deleteObjectJson = (e) => {
        setBody(e.updated_src);
    }

    const showAttributes = () => {
        return attributes.map((attr, index) => {
            return (
                <div className={"inline-flex"} key={index}>
                    <h5>{attr.name}</h5>
                    <div className={"ml-2"}>
                        {attr.type}
                    </div>
                    <CopyToClipboard text={attr.name} onCopy={() => props.setVisibleSidebar(false)}>
                        <FontAwesomeIcon icon={'fa-regular fa-copy'} color={'#FD971F'} size="xs"
                                         className={"btn-actions btn-fontawesome"} />
                    </CopyToClipboard>
                </div>
            )
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            'method': method,
            'url': urlPage,
            'response_key': responseKey,
            'body': body,
            'response_timeout': responseTimeout,
            'authorization': authorization
        }

        props.setFormData(formData);
        props.hideProviderWebhook();

    }

    const handleDelete = () => {
        if (!condition) {
            props.setFormData(null);
            props.hideProviderWebhook();
        } else {
            refToast.current.show({severity: 'error', summary: 'Configuración Webhook',
                detail: 'No se puede eliminar la configuración de Webhook. Se encuentra asociada a una condición de bot. Elimine antes la configuración de condición.'});
        }
    }

    const handleAuthorizationType = (value) => {
        let _value = value === 'Basic' ? {user: '', password: ''} : '';
        setAuthorization((prevState) => {
            return {
                ...prevState,
                authorization_type: value,
                value: _value
            }
        });
    }

    const handleAuthorizationValue = (authorization_type, field, value) => {
        let _authorization = {...authorization.value}
        if (authorization_type === 'Basic'){
            if (field === 'user'){
                _authorization.user = value;
            } else {
                _authorization.password = value;
            }
        } else if (authorization_type === 'Bearer'){
            _authorization = value;
        } else {
            _authorization = null;
        }

        setAuthorization((prevState) => {

            return {
                ...prevState,
                value: _authorization
            }
        });
    }

    const getAuthorizationBasic = () => {
        return (
            <Fragment>
                <div className="field col">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={authorization.value.user}
                                           onChange={(e) => handleAuthorizationValue('Basic', 'user', e.target.value)} />
                        <label htmlFor="inputtext">User</label>
                    </span>
                </div>
                <div className="field col">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={authorization.value.password}
                                           onChange={(e) => handleAuthorizationValue('Basic', 'password', e.target.value)} />
                        <label htmlFor="dropdown">Password</label>
                    </span>
                </div>
            </Fragment>
        )
    }

    const getAuthorizationBearer = () => {
        return (
            <div className="field col">
                <span className="p-float-label">
                    <InputText id="inputtext" value={authorization.value}
                                       onChange={(e) => handleAuthorizationValue('Bearer', null, e.target.value)} />
                    <label htmlFor="dropdown">Token</label>
                </span>
            </div>
        )
    }

    const getAuthorizationForm = () => {
        let form = null;
        if (authorization.authorization_type === 'Basic') {
            form = getAuthorizationBasic();
        } else if (authorization.authorization_type === 'Bearer'){
            form = getAuthorizationBearer();
        }
        return form;
    }

    const labelSubmit = props.formData !== null ? 'Modificar' : 'Crear';
    let form = (
        <div className="grid p-fluid mt-2">
            <div className="field col mt-3">
                <div className="">
                    {/*<h5>Webhooks</h5>*/}
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={method} options={methodOptions}
                                          onChange={(e) => setMethod(e.value)}/>
                                <label htmlFor="dropdown">Método</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={urlPage}
                                           onChange={(e) => setUrlPage(e.target.value)} />
                                <label htmlFor="inputtext">Url</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={responseKey}
                                           keyfilter={/^[^*!#,<>+\s+$/]/}
                                           onChange={(e) => setResponseKey(e.target.value)} />
                                <label htmlFor="inputtext">Key de respuesta</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <InputNumber id="inputtext" value={responseTimeout} min={1} max={20}

                                           onValueChange={(e) => setResponseTimeout(e.value)} />
                                <label htmlFor="inputtext">Tiempo máximo de respuesta</label>
                            </span>
                        </div>
                        <Button icon="pi pi-th-large" onClick={() => props.setVisibleSidebar(true)} className="p-button-rounded btn-actions-default" />
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown_auth" value={authorization.authorization_type}
                                          options={authorizationOptions} showClear
                                          optionLabel="label" optionValue="value"
                                          onChange={(e) => handleAuthorizationType(e.value)}/>
                                <label htmlFor="dropdown_auth">Authorization</label>
                            </span>
                        </div>
                        {getAuthorizationForm()}
                    </div>
                </div>
                <div>
                    <ReactJson src={body} theme="monokai" name={null} enableClipboard={false}
                               onEdit={(e) => editJson(e)} style={{minHeight: '20em' }}
                               onDelete={(e) => deleteObjectJson(e)}
                               onAdd={(e) => true}
                               validationMessage={"Verifique las variables disponibles"}/>
                </div>
                <div className="field col mt-3">

                     <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Button label={labelSubmit} disabled={submitDisabled} className={"mt-4"}
                                        onClick={(e) => handleSubmit(e)}
                                icon={props.formData !== null ? 'pi pi-pencil' : 'pi pi-plus'}/>
                            </span>
                        </div>
                        {props.formData !== null && (
                            <div className="field col">
                                <span className="p-float-label">
                                    <Button className="p-button-raised btn-danger ml-auto mt-4"
                                            label={'Eliminar'} onClick={handleDelete} icon={'pi pi-times'}/>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
    return form;
}

export default QuestionnaireProviderWebhookForm;