import React, { useState, useEffect, useRef, Fragment } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ApiLoan from '../../../service/ApiLoanService';
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import './QuestionnaireConditionsForm.css';
import { OTHER, GREATER, LESS } from '../../../shared/questionnaireConditionTypes';

const QuestionnaireConditionsForm = (props) => {
    const [conditions, setConditions] = useState([]);
    const maxConditions = 20;
    const [conditionTypes, setConditionTypes] = useState([]);
    const [integrationTypes, setIntegrationTypes] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [activeConditionsOther, setActiveConditionsOther] = useState(false);
    const [sequenceOptions, setSequenceOptions] = useState([]);
    const refToast = useRef(null);

    useEffect(() => {
        let apiService = new ApiLoan();
        apiService.getResources({
            url: '/questionnaire_condition_types'
        }).then(response => {
            setConditionTypes(response.data.objects)
        })
        apiService.getResources({
            url: '/questionnaire_integration_types'
        }).then(response => {
            let integrationConfig = props.questionnaire.config || { sheet: null, provider_webhook: null };
            let integrationTypes = response?.data?.objects || [];
            if (integrationConfig.sheet === null) {
                integrationTypes = integrationTypes.filter(integration => integration.name !== 'sheet');
            }
            if (integrationConfig.provider_webhook === null) {
                integrationTypes = integrationTypes.filter(integration => integration.name !== 'webhook');
            }
            setIntegrationTypes(integrationTypes)
        })
        let questionnaires = props.questionnaires || [];
        let newSequenceOptions = questionnaires.map((_, index) => ({ name: 'Secuenacia ' + (index + 1), value: index }));
        newSequenceOptions = newSequenceOptions.filter((element, _) => element.value !== props.questionnaireIndex);
        setSequenceOptions(newSequenceOptions);
    }, []);

    useEffect(() => {
        if (conditions.map((item) => item.questionnaire_condition_type_id).includes(null)) {
            setDisableSubmit(true)
        }
        else if (conditions.some((item) => 
            (item.questionnaire_condition_type_id !== OTHER) && 
            (item.value === null || item.value === ''))) {
            setDisableSubmit(true);
        }
        else if (conditions.map((item) => item.questionnaire_integration_type_id).includes(null)) {
            setDisableSubmit(true)
        }
        else if (conditions.map((item) => item.sequence).includes(null)) {
            setDisableSubmit(true)
        }
        else {
            setDisableSubmit(false)
        }
        if (conditions.find((item) => item.questionnaire_condition_type_id === OTHER)) {
            setActiveConditionsOther(true)
        } else {
            setActiveConditionsOther(false)
        }
    }, [conditions])

    useEffect(() => {
        let _new_data = []
        if (props.formData.length === 0) {
            _new_data.push({
                questionnaire_condition_type_id: null,
                questionnaire_id: null,
                root_questionnaire_id: null,
                questionnaire_integration_type_id: null,
                value: null,
                active: false,
                more_conditions: false,
                sequence: null,
            });
        } else {
            _new_data = props.formData.map((item, index) => {
                return {
                    ...item,
                    more_conditions: index < props.formData.length - 1,
                }
            });
        }
        setConditions(_new_data)
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.setFormData(conditions);
        props.hideModalQuestionnaireConditions();
    }

    const handleDelete = () => {
        props.setFormData([]);
        props.hideModalQuestionnaireConditions();
    }

    const updateFieldCondition = (item, index, value) => {
        setConditions((prevState) => {
            let values = [...prevState];
            values[index][item] = value;
            return values;
        });
    }

    const filterOptionOther = () => {
        return conditionTypes.filter((element) => element.id !== OTHER);
    }

    const updateMoreConditionHandler = (value, index) => {
        if (value) {
            setConditions((prevState) => {
                let new_values = [...prevState];
                new_values.at(index).more_conditions = true

                new_values.push({
                    questionnaire_condition_type_id: null,
                    questionnaire_id: null,
                    root_questionnaire_id: null,
                    questionnaire_integration_type_id: null,
                    value: null,
                    active: false,
                    sequence: null,
                    more_conditions: false,
                });
                return new_values;

            });
        } else {
            if (conditions.length > 1) {
                setConditions((prevState) => {
                    let new_values = [...prevState];
                    new_values.splice(index + 1, 1);
                    new_values.at(-1).more_conditions = false;
                    return new_values;
                });
            }
        }
    }

    const addNewConditionField = (more_conditions, index) => {
        return (
            <div className="field col-8">
                <label htmlFor="new_condition">Nueva Condición</label>
                <span className="p-float-label">
                    <InputSwitch id="new_condition"
                        checked={more_conditions}
                        onChange={(e) => updateMoreConditionHandler(e.value, index)}
                        disabled={index === maxConditions - 1}
                    />
                </span>
            </div>
        )
    }

    const getForm = () => {
        return conditions.map((item, index) => {

            let isOther = item.questionnaire_condition_type_id === OTHER;
            let conditioOptions = isOther ? conditionTypes : activeConditionsOther ? filterOptionOther() : conditionTypes;

            return (
                <Fragment key={index}>
                    <div className={'mt-2 mb-3 condition-number'}>
                        Condición {index + 1}
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={item.questionnaire_condition_type_id} options={index === 0 ? filterOptionOther() : conditioOptions}
                                    optionLabel={"description"} optionValue={"id"}
                                    onChange={(e) => {
                                        updateFieldCondition('questionnaire_condition_type_id', index, e.value);
                                        if (e.value === OTHER) updateFieldCondition('value', index, '');
                                        else if (e.value === LESS || e.value === GREATER) updateFieldCondition('value', index, item.value.replace(/\D/g, ''));
                                    }} />
                                <label htmlFor="dropdown">Tipo de condición</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <InputText id="inputtext" disabled={isOther} value={item.value || ''}
                                    onChange={(e) => {
                                        let inputValue = e.target.value;
                                        if ([LESS, GREATER].includes(item.questionnaire_condition_type_id)) inputValue = inputValue.replace(/\D/g, '')
                                        updateFieldCondition('value', index, inputValue)}
                                    } />
                                <label htmlFor="inputtext">Defina texto, palabra o número</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={item.questionnaire_integration_type_id} options={integrationTypes}
                                    optionLabel={"description"} optionValue={"id"}
                                    onChange={(e) => {
                                        updateFieldCondition('questionnaire_integration_type_id', index, e.value);
                                    }} />
                                <label htmlFor="dropdown">Tipo de integración</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown id="sequence" value={item.sequence} optionLabel="name"
                                    optionValue="value"
                                    options={sequenceOptions}
                                    onChange={(e) => updateFieldCondition('sequence', index, e.value)} />
                                <label htmlFor="sequence">Seleccione secuencia</label>
                            </span>
                        </div>
                        <div className="field col inline-flex">
                            <div className="field">
                                <label htmlFor="active">Habilitado</label>
                                <span className="p-float-label">
                                    <InputSwitch id="active" checked={item.active} onChange={(e) => updateFieldCondition('active', index, e.value)} />
                                </span>
                            </div>
                            {addNewConditionField(item.more_conditions, index)}
                        </div>
                    </div>
                </Fragment>
            )
        });
    }

    let form = (
        <div className="grid p-fluid mt-3 mb-3">
            <div className="field col mt-3">
                <div className="">
                    <Toast ref={refToast} />
                    {getForm()}
                </div>
                <div className='p-inputgroup'>
                    <Button label={props.formData.length !== 0 ? 'Guardar' : 'Crear'}
                        onClick={(e) => handleSubmit(e)} disabled={disableSubmit} className="p-button-raised ml-auto" style={{ width: props.formData.length !== 0 ? '50%' : '100%' }}
                        icon={props.formData.length !== 0 ? 'pi pi-pencil' : 'pi pi-plus'}
                    />
                    <span>&nbsp;</span>
                    {props.formData.length !== 0 &&
                        <Button className="p-button-raised btn-danger ml-auto" label={'Eliminar'} style={{ width: '50%' }}
                            onClick={handleDelete}
                            icon={'pi pi-times'}
                        />
                    }
                </div>
            </div>
        </div>
    )

    return form;
}

export default QuestionnaireConditionsForm;
